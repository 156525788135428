<template>
  <div class="content-wrapper">
    <div class="login-container">
      <img src="../assets/homepage_logo.png" alt="logo">
      <h1>Login</h1>
        <label>Email</label>
        <input type="text" v-model="email" />
        <label>Password</label>
        <input type="password" v-model="password" />
      <va-button class="btn-login" @click="login">Login</va-button>
      <span>New user?</span>
      <p><va-button class="btn-signup" to="/register">Sign up</va-button></p>
    </div>
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import firebase from 'firebase';
  import { useRouter } from 'vue-router';

  const email = ref('');
  const password = ref('');
  const router = useRouter();
  
  const login = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email.value, password.value) // need .value because ref()
      .then(() => {
        router.push('/')
      })
      .catch(error => {
        console.log(error.code)
        // TODO: update "alert" to be a toast message
        alert(error.message);
      });
  }
</script>

<style scoped>
.content-wrapper {
  display: flex;
  justify-content: center;
}

.login-container {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  width: 350px;
  border-radius: 8px;
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
}
  .login-container > img {
    width: 200px;
    align-self: center;
    margin: 10px 0 20px 0;
  }
  .login-container > label {
    text-align: left;
    font-size: 14px;
    margin: 15px 0 4px 0;
    color: #6D6D6D;

  }
  .login-container > input {
    background: #F3F5FA;
    border: none;
    border-radius: 4px;
    height: 24px;
  }

  .btn-login {
    margin: 20px 0 20px 0;
  }

  .btn-signup {
    margin: 20px 0 10px 0;
  }

</style>